const HTTPS = "https";
const HOST = "lux212.azurewebsites.net";
const PORT = "";
const VERSION = "Api";

// export const BASE_URL = HTTPS + "://" + HOST + ":" + PORT + "/" + VERSION + "/";
export const BASE_URL = HTTPS + "://" + HOST + "" + PORT + "/" + VERSION + "/";
export const BASE_URLS = HTTPS + "://" + HOST + "" + PORT + "/" ;

export const Get_Game_Types_Post = BASE_URL + "GetGameTypes";
export const Get_Providers_Post = BASE_URL + "GetProviders";
export const Login_User_Post = BASE_URL + "login";
export const ADS_Post = BASE_URL + "Ads";
export const Instructions_Post = BASE_URL + "Instructions";
export const GetGames_Post = BASE_URL + "GetGames";
export const LaunchGame_Post = BASE_URL + "LaunchGame";//
export const CreditAccount_Post = BASE_URL + "CreditAccount";
export const ResetPassword_Post = BASE_URL + "ResetPassword";
export const Register_Post = BASE_URL + "Register";
export const CheckUsername_Post = BASE_URL + "CheckUsername";
export const GetBalance_Post = BASE_URL + "GetBalance";
export const UserProfile_Post = BASE_URL + "UserProfile";
export const BankTransactions_Post = BASE_URL + "BankTransactions";
export const History_Post = BASE_URL + "History";
export const SaveEmail_Post = BASE_URL + "SaveEmail";
export const ChangePassword_Post = BASE_URL + "ChangePassword";
export const RequestOtp_Post = BASE_URL + "RequestOtp";
export const SavePhone_Post = BASE_URL + "SavePhone";
export const SaveBankAcc_Post = BASE_URL + "SaveBankAcc"//
export const Transactions_Post = BASE_URL + "Transactions";
export const MyBankAccounts_Post = BASE_URL + "MyBankAccounts";
export const TransferCredit_Post = BASE_URL + "TransferCredit";
export const SaveDebitInfo_Post = BASE_URL + "SaveDebitInfo";
export const VerifyOtp_Post = BASE_URL + "VerifyOtp";
export const PaymentGateways_Post = BASE_URLS + "Pay/PaymentGateways";
export const Deposit_Post = BASE_URLS + "Pay/Deposit";
export const uploadReceipts_Post = BASE_URL + "uploadReceipts.php"///
export const SaveCreditInfo_Post = BASE_URL + "SaveCreditInfo";
export const SetPin_Post = BASE_URL + "SetPin";